<template>
  <div>
    <div class="table_common" v-show="privateResource">
      <div class="search_flex_box flex_center_between_box">
        <div class="search_condition">
          <el-form ref="form" :model="form">
            <el-form-item label="采购商名称:" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="合作年限:" prop="age">
              <el-select v-model="form.age" placeholder="" class="select_class">
                <el-option v-for="item in options" :key="item.codeState" :label="item.value" :value="item.codeState">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="search_boxs flex_center_between_box">
          <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
            <div class="search_button_box" @click="searchForm()">
              <i class="iconfont icon-10sousuo"></i>
            </div>
          </el-tooltip>
          <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
            <div class="reset_button_box" @click="cleargetDate()">
              <i class="iconfont icon-shuaxin"></i>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="table_box">
        <div class="table_box_top flex_center_between_box">
          <div class="table_box_top_title">我的伙伴</div>
          <div class="flex_center_end_box">
            <div class="search_button button" @click="dialogAddPartner();">添加伙伴</div>
            <div class="search_button button data_export">数据导出</div>
          </div>
        </div>
        <div class="table_box_bottom">
          <el-table :data="tableData" stripe border style="width: 100%">
            <template slot="empty">
              <NoData />
            </template>
            <el-table-column label="采购商编号" prop="coreEnterpriseId" :resizable="false" align="center" show-overflow-tooltip
              :width="idWidth" />
            <el-table-column label="采购商名称" prop="coreEnterpriseName" :resizable="false" align="left" header-align="center"
              show-overflow-tooltip :width="nameWidth">
              <template slot-scope="scope">
                <div class="link" @click="detailIndex(scope.row)">{{ scope.row.coreEnterpriseName }}</div>
              </template>
            </el-table-column>
            <el-table-column label="采购商评级" prop="grade" align="center" :width="width120" :resizable="false"
              show-overflow-tooltip />
            <el-table-column label="采购商联系人" prop="contacts" align="center" show-overflow-tooltip :resizable="false"
              :width="width120" />
            <el-table-column label="采购商电话" prop="phone" align="center" border :resizable="false" :width="width150" />
            <el-table-column label="采购商邮件" prop="email" align="center" :width="emailWidth" :resizable="false"
              show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.email }}
                <!-- <div v-if="scope.row.email == '暂无邮箱'">{{scope.row.email}}</div>
                                <a v-else class="link" href="mailto:admin@basechina.net">{{scope.row.email}}</a> -->
              </template>
            </el-table-column>
            <el-table-column label="合作年限" prop="age" align="center" :resizable="false">
              <template slot-scope="scope">
                <div v-if="scope.row.age == '0'">新客户</div>
                <div v-else-if="scope.row.age == '1'">1-3年</div>
                <div v-else>3年以上</div>
              </template>
            </el-table-column>
            <el-table-column label="合作描述" prop="suppDesc" align="center" :resizable="false" show-overflow-tooltip />
            <el-table-column label="操 作" align="center" :resizable="false" fixed="right">
              <template slot-scope="scope">
                <div class="table_oper flex_center">
                  <el-tooltip class="item_color" effect="light" content="编辑" placement="top">
                    <i class="iconfont icon-bianji" @click="dialogEditPartner(scope.row, scope.$index)"></i>
                  </el-tooltip>
                  <!-- <el-tooltip class="item_color" effect="light" content="详情" placement="top">
                                        <i class="iconfont icon-shuxingliebiaoxiangqing" @click="dialogLookPartner(scope.row)"></i>
                                    </el-tooltip> -->
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" align="center" width=30 class-name="add_padding" :resizable="false" fixed="right">
              <template slot="header">
                <div class="table_add">
                  <i class="iconfont icon-jiahao"></i>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Page :totalFont="true" :currentPage=page.pageNo :total="page.total" @onPageChange="onPageChange"></Page>
        </div>
      </div>
    </div>
    <div v-show="detailDisplay">
      <!-- <div class="second_return_icon" @click="returnButton() ">
                <i class="iconfont icon-fanhui" ></i>
                伙伴详情
            </div> -->
      <Company ref="Company"></Company>
    </div>
    <!-- 添加伙伴弹框 -->
    <el-dialog :title="dialogTit" :visible.sync="applyFinancingDialog" center class="dialog_width500"
      :close-on-click-modal="false" :before-close="onColseFinancing">
      <div class="add_invoice_message inner_common">
        <el-form :model="addPartner" ref="addPartner" :rules="rules">
          <el-form-item label="采购商编号:" v-if="index != -1">
            <el-input v-model="addPartner.coreEnterpriseId" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="采购商名称:" prop="coreEnterpriseName">
            <el-autocomplete class="inline-input" v-model="addPartner.coreEnterpriseName" :fetch-suggestions="querySearch"
              :trigger-on-focus="false" @select="handleSelect">
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="采购商联系人:" prop="contacts">
            <el-input v-model="addPartner.contacts"></el-input>
          </el-form-item>
          <el-form-item label="电话号码:" prop="phone">
            <el-input v-model="addPartner.phone" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="邮箱账号:" prop="email">
            <el-input v-model="addPartner.email"></el-input>
          </el-form-item>
          <el-form-item label="合作年限:" prop="age">
            <el-select v-model="addPartner.age" placeholder="">
              <el-option v-for="item in options" :key="item.index" :label="item.value" :value="item.codeState">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合作信息描述:" prop="suppDesc">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" maxlength="200" show-word-limit
              v-model="addPartner.suppDesc">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="onColseFinancing" v-if="index != -2">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="applyFinancingDialogFalse()" v-if="index != -2">提
          交</el-button>
        <el-button class="footer_button1" type="primary" @click="onColseFinancing" v-if="index == -2">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getMyPartnerList, mypartner, findCodeInfoByKey, getSupplierName, CompanyNameList } from "@/api/supplier.js";
import Company from '../components/companyDetail.vue'
import { mixins1 } from "@/mixins/index";
import NoData from '@/components/noData'
import Page from '@/components/page'
export default {
  mixins: [mixins1],
  components: { Page, NoData, Company },
  data() {
    const valiedateCom = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入采购商名称'))
      }
      return cb()
    }
    const valiedateLin = (rule, val, cb) => {
      if (val.length === 0) {
        return cb(new Error('请输入采购商联系人'))
      } else if (!/[\u4e00-\u9fa5_a-zA-Z]+$/.test(val)) {
        return cb(new Error('请输入正确的联系人格式'))
      }
      return cb()
    }
    const valiedateTel = (rule, val, cb) => {
      if (val.length === 0) {
        return cb(new Error('请输入电话号码'))
      } else if (!/^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/.test(val))
        return cb(new Error('请输入正确的电话号码格式'))
      return cb()
    }
    const valiedateEmail = (rule, val, cb) => {
      if (val.length === 0) {
        return cb(new Error('请输入邮箱账号'))
      } else if (!/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(val))
        return cb(new Error('请输入正确的邮箱账号格式'))
      return cb()
    }
    const valiedateYear = (rule, val, cb) => {
      if (val.length === 0) {
        return cb(new Error('请输入合作年限'))
      }
      return cb()
    }
    return {
      aa: false,
      idWidth: 140,
      dialogLook: false,
      rules: {
        coreEnterpriseName: [
          { required: true, validator: valiedateCom, trigger: 'blur' }
        ],
        contacts: [
          { required: true, validator: valiedateLin, trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: valiedateTel, trigger: 'blur' }
        ],
        email: [
          { required: true, validator: valiedateEmail, trigger: 'blur' }
        ],
        age: [
          { required: true, validator: valiedateYear, trigger: 'blur' }
        ]
      },
      form: {
        name: '',
        age: '',
      },
      tableData: [
      ],
      dialogTit: '添加伙伴',
      applyFinancingDialog: false,
      addPartner: {
        coreEnterpriseId: '',
        coreEnterpriseName: '',
        contacts: '',
        phone: '',
        email: '',
        age: '',
        suppDesc: ''
      },
      options: [],
      dataname: [],
      timeout: null,
      emailWidth: 280,
      start: '',
      index: '0',
      detailDisplay: false,
      detailDisplay1: false,
      detailDisplay2: false,
      detailDisplay3: false,
      privateResource: true,
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
    }
  },
  created() {
    this.tableWidth()
    this.resizeFn()
    this.getDate()
    this.getSelect()
  },
  methods: {
    onColseFinancing() {
      this.applyFinancingDialog = false
      this.$refs.addPartner.resetFields()
    },
    async getDate() {
      let data = await getMyPartnerList('pageNo=' + this.page.pageNo + '&pageSize=' + this.page.pageSize +
        '&purchaseName=' + this.form.name + '&age=' + this.form.age)
      this.tableData = data.data.records
      this.page.total = data.data.total
    },
    // 返回上一页
    returnButton() {
      this.detailDisplay = false
      this.privateResource = true
      window.scrollTo(0, 0)
    },
    detailIndex(row) {
      this.$refs.Company.getCompany(row.coreEnterpriseName)
      // this.$refs.Company.indexToolList(row.coreEnterpriseName)
      this.privateResource = false
      this.detailDisplay = true
    },
    async getSupplierName() {
      let data = await CompanyNameList('comName=' + this.addPartner.coreEnterpriseName)
      this.dataname = data.data
      this.dataname.forEach((item, index) => {
        this.dataname[index].value = item.company_name
      });
      console.log(this.dataname)
    },
    async getSelect() {
      let data = await findCodeInfoByKey('ms=7')
      this.options = data.data
    },
    async dialogAddPartner() {
      this.dialogTit = "添加伙伴"
      this.index = '-1'
      this.applyFinancingDialog = true
      this.$nextTick(() =>
        this.$refs.addPartner.resetFields()
      )
      if (this.addPartner.id) {
        delete this.addPartner.id
      }
    },
    async applyFinancingDialogFalse() {
      this.$refs.addPartner.validate(async (valid) => {
        if (valid) {
          this.applyFinancingDialog = false
          let data = await mypartner(this.addPartner)
          if (data.code == 200) {
            this.getDate()
          } else if (data.code == 100) {
            this.$message({
              message: data.msg,
              type: 'warning'
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    searchForm() {
      this.page.pageNo = 1
      this.getDate()
    },
    cleargetDate() {
      this.$refs.form.resetFields()
      this.page.pageNo = 1
      this.getDate()
    },
    dialogEditPartner(item, index) {
      this.applyFinancingDialog = true
      this.dialogTit = "编辑我的伙伴"
      this.index = index
      console.log(typeof (item.age))
      this.$nextTick(() =>
        this.addPartner = JSON.parse(JSON.stringify(item))
      )
      console.log(this.$refs)
    },
    dialogLookPartner(item) {
      this.dialogTit = "伙伴详情"
      this.index = '-2'
      this.addPartner = JSON.parse(JSON.stringify(item))
      this.applyFinancingDialog = true
      this.dialogLook = true
    },
    handleSelect(item) {
      console.log(item)
    },
    querySearch(queryString, cb) {
      this.getSupplierName().then(() => {
        console.log(queryString)
        var restaurants = this.dataname;
        var results = []
        if (restaurants) {
          results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
          // results = queryString ? restaurants : [];
        }
        cb(results);
      })
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
      };
    },
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.emailWidth = 280
        this.idWidth = 140
      } else {
        this.emailWidth = 187
        this.idWidth = 120
      }
    },
  }
}
</script>